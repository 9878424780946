import { Box } from "@mui/material";
import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { ModelShape } from "../../shapes";
import { modelToMML } from "../../utils";

type MMLCharacterViewProps = {
  model: ModelShape;
};

export default function MMLCharacterView(props: MMLCharacterViewProps) {
  const source = modelToMML(props.model);

  return (
    <Box sx={{ position: "relative", top: -16 }}>
      <SyntaxHighlighter language="xml" style={darcula} wrapLongLines={true}>
        {source}
      </SyntaxHighlighter>
    </Box>
  );
}
