import { BindingShape, ModelShape, SlotShape, TraitShape } from "./shapes";

export const slotToName: Record<string, string> = {
  Body: "🕺 Body",
  Arms: "🤜🤛 Arms",
  ArmL: "🤛 ArmL",
  ArmR: "🤜 ArmR",
  Legs: "🦵🦶 Legs",
  LegL: "🦵 LegL",
  LegR: "🦶 LegR",
  Hands: "🤲🤚 Hands",
  HandL: "🤚 HandL",
  HandR: "🤲 HandR",
  Feet: "👣👟 Feet",
  Lower: "👖 Lower",
  Upper: "👕 Upper",
  Shoes: "👟 Shoes",
  Head: "👨‍🦰 Head",
  Crown: "👑 Crown",
  Jaw: "🦷 Jaw",
  Face: "👧 Face",
  Eyes: "👁️‍🗨️ Eyes",
  Glasses: "👓 Glasses",
  Ears: "👂 Ears",
  Earring: "👂 Earring",
  Hat: "🎩 Hat",
  Hair: "👱‍♀️ Hair",
  Mouth: "👄 Mouth",
  Lips: "👄 Lips",
  Coat: "🧥 Coat",
  Wings: "🦅 Wings",
  Tail: "🐶 Tail",
};

export function slotToMML(slot: string, mesh: string, color?: string): string {
  const colorClause = color ? ` color="${color}"` : "";
  return `\t<m-model type="${slot}" src="${mesh}"${colorClause}></m-model>`;
}

export function saveFile(
  content: string,
  fileName: string,
  type: string
): void {
  const fileContent = content;
  const bb = new Blob([fileContent], { type });
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(bb);
  a.click();
}

export function modelToMML(model: ModelShape): string {
  const filteredSlots = Object.keys(model).filter(
    (slot) => slot !== "Body" && slot !== "undefined"
  );

  const slotText = filteredSlots.map((slot) =>
    slotToMML(slot, model[slot].mesh, model[slot].color)
  );

  return `<m-character src="${model["Body"]?.mesh}">\n${slotText.join(
    "\n"
  )}\n</m-character>`;
}

export function traitsToModel(
  traits: TraitShape,
  bindings: BindingShape
): ModelShape {
  const model: ModelShape = {};

  Object.keys(traits).forEach((trait: string) => {
    const value = traits[trait];

    // apply meshes and customizations for every trait
    if (bindings[trait] && bindings[trait][value]) {
      const binding = bindings[trait][value];
      const slot = binding.slot;
      const mesh = binding.mesh;
      const color = binding.color;

      if (!model[slot]) {
        model[slot] = {
          mesh: "",
        };
      }

      if (mesh) {
        model[slot].mesh = mesh;
      }

      if (color) {
        model[slot].color = color;
      }
    }
  });

  // TODO: for every slot bound, the mesh should not be empty

  return model;
}
