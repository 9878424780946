import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Paper,
  Stack,
  ThemeProvider,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { Simulate } from "react-dom/test-utils";

(window as any).global = window;
import createPersistedState from "use-persisted-state";

import { uploadString } from "../contentAddressedStorage";
import { ModelShape } from "../shapes";
import { darkTheme } from "../theme";
import { modelToMML, slotToName } from "../utils";
import Bindings from "./bindings/Bindings";
import Character from "./character/Character";
import CharacterBuilder from "./character/CharacterBuilder";
import MMLCharacterView from "./character/MMLCharacterView";
import VisualizedCharacterView from "./character/VisualizedCharacterView";
import { CollectionPanel } from "./Collection";
import Dropdown from "./generic/Dropdown";
import MeshSelector from "./generic/MeshSelector";

const useSlotsState = createPersistedState("slots");

export default function SimpleMain() {
  const [slots, setSlots] = useSlotsState([]);

  const [mode, selectMode] = React.useState("Preview");

  const model = {} as ModelShape;
  for (const slot of slots) {
    model[slot.slot] = {
      mesh: slot.mesh,
    };
  }

  // const slots = Object.keys(model).map((slot) => {
  //   return { slot, mesh: model[slot].mesh };
  // });

  const slotElements = slots.map((slot) => (
    <ComponentSelector
      key={slot.slot + slot.mesh}
      slot={slot.slot}
      setSlot={(newSlot) => {
        const newSlots = [...slots];
        const index = newSlots.findIndex((s) => s.slot === slot.slot);
        newSlots[index].slot = newSlot;
        setSlots(newSlots);
      }}
      mesh={slot.mesh}
      setMesh={(newMesh) => {
        const newSlots = [...slots];
        const index = newSlots.findIndex((s) => s.slot === slot.slot);
        newSlots[index].mesh = newMesh;
        setSlots(newSlots);
      }}
    />
  ));

  return (
    <ThemeProvider theme={darkTheme}>
      <Selector selectMode={selectMode} />
      <Box sx={{ width: "100%", height: "100%", position: "absolute" }}>
        <Stack
          direction="row"
          sx={{ width: "100%", height: "100%", position: "absolute" }}
        >
          <Box sx={{ width: "50%", m: 1, p: 1, color: "white" }}>
            <h2 style={{ marginTop: 0 }}>MML Character Creator</h2>

            {slotElements}

            {/*<CharacterBuilder*/}
            {/*  collection={collection}*/}
            {/*  bindings={bindings}*/}
            {/*  traits={character}*/}
            {/*  setTrait={setTrait}*/}
            {/*/>*/}
            {/*<MMLCharacterView model={model} />*/}

            <Button
              variant={"outlined"}
              onClick={() => {
                const newSlots = [...slots, { slot: "none", mesh: "" }];
                setSlots(newSlots);
              }}
            >
              Add Slot
            </Button>

            {/*<Stack direction="row" spacing={2}>*/}

            {/*</Stack>*/}

            {/*<JsonView view={character} />*/}
            <Button
              onClick={() => {
                setSlots([]);
              }}
              variant={"outlined"}
              sx={{ m: 1 }}
            >
              Clear Slots
            </Button>

            <Button
              variant={"outlined"}
              onClick={async () => {
                const mml = modelToMML(model);
                const response = await uploadString(mml);
                await navigator.clipboard.writeText(response.toString());
                console.log(response.toString());
              }}
            >
              Copy to Clipboard
            </Button>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {mode === "Preview" ? (
              <VisualizedCharacterView model={model} />
            ) : (
              <MMLCharacterView model={model} />
            )}
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

function ComponentSelector(props: {
  slot: string;
  setSlot: (slot: string) => void;
  mesh: string;
  setMesh: (mesh: string) => void;
}) {
  return (
    <Paper sx={{ p: 1, m: 1 }} elevation={3}>
      <Stack direction="row" spacing={1}>
        <Box sx={{ width: "25%" }}>
          {" "}
          <Dropdown
            label={"Slot"}
            values={Object.keys(slotToName)}
            valueToDisplayName={(slot) =>
              slotToName[slot] || slotToName["none"]
            }
            value={props.slot}
            setValue={props.setSlot}
          />
        </Box>
        <Box sx={{ width: "75%" }}>
          <MeshSelector mesh={props.mesh} setMesh={props.setMesh} />
        </Box>
      </Stack>
    </Paper>
  );
}

function Selector(props: { selectMode: (mode: string) => void }) {
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        m: 1,
        zIndex: 1000,
      }}
    >
      <ButtonGroup variant="outlined">
        <Button onClick={() => props.selectMode("Preview")}>3D View</Button>
        <Button onClick={() => props.selectMode("MML")}>MML View</Button>
      </ButtonGroup>
    </Box>
  );
}
