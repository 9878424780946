import { Box } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";

import { ModelShape } from "../../shapes";
import { modelToMML } from "../../utils";

type MMLCharacterViewProps = {
  model: ModelShape;
};

export default function VisualizedCharacterView(props: MMLCharacterViewProps) {
  const source = modelToMML(props.model);
  console.log(source);

  const ref: React.Ref<HTMLIFrameElement> = React.useRef(null);

  useEffect(() => {
    if (
      ref.current &&
      ref.current.contentDocument &&
      ref.current.contentDocument.getElementById("scene")
    ) {
      console.log("UPDATING HTML", source);
      ref.current.contentDocument.getElementById("scene").innerHTML = source;
    }
  });

  return (
    // <Box>
    <iframe
      ref={ref}
      onLoad={() => {
        console.log("SETTTING");
        ref.current.contentDocument.getElementById("scene").innerHTML = source;
      }}
      srcDoc={`
      
      <html>
      <head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.css"/>
       
</head>
            <body style="overflow: hidden">
             <m-group id="scene"></m-group>
                <script src="https://mml-view.space/index.js?&anim=idle&exposure=2"/></script>
            </body>
      </html>
      
      `}
      style={{
        position: "relative",
        right: 0,
        top: 0,
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
        border: "none",
      }}
      frameBorder={0}
    ></iframe>
    // </Box>
  );
}
