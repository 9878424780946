import Button from "@mui/material/Button";
import * as React from "react";

type TextImporterProps = {
  label: string;
  onFile: (file: File) => void;
};

export default function FileImporter(props: TextImporterProps) {
  return (
    <Button variant="outlined" component="label">
      {props.label}
      <input
        hidden
        accept=".glb"
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files[0]) {
            props.onFile(files[0]);
          }
        }}
      />
    </Button>
  );
}
