import * as CryptoJS from "crypto-js";
import md5 from "crypto-js/md5";

export async function uploadString(str: string): Promise<URL> {
  const encoder = new TextEncoder();
  return uploadBlob(new Blob([encoder.encode(str)]), "text");
}

export async function uploadFile(file: File): Promise<URL> {
  const buffer = await file.arrayBuffer();
  // return uploadBuffer(buffer);
  return uploadBlob(new Blob([buffer]), file.name);
}

export async function uploadBlob(blob: Blob): Promise<URL> {
  const buffer = await blob.arrayBuffer();
  const wa = CryptoJS.lib.WordArray.create(buffer);
  const hash = md5(wa).toString();

  // check existence of hash on content addressed storage already

  const url = new URL("https://mml-avatar.space/upload/" + hash);

  const result = await fetch(url, {
    method: "HEAD",
  });

  if (result.status === 404) {
    const result = await fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/octet-stream",
      },
      body: buffer,
    });

    console.log("UPLOAD COMPLETE");
    console.log(result.status);
  } else {
    console.log("ALREADY FOUND " + result.status);
  }

  // upload

  // return URL to uploaded file

  return url;
}

export async function uploadBlobToIpfs(
  blob: Blob,
  fileName: string
): Promise<URL> {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic MkVQNVNFeEFhRUJ2UDVxbXJHOEJRMWtaZFBaOjAzOTkwODRhMzc0MjVkOWRiNzlmZTVmMDEwNmNiNTY5"
  );

  const formdata = new FormData();
  formdata.append("", blob, fileName);

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const result = await fetch(
    "https://ipfs.infura.io:5001/api/v0/add",
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

  const hash = result.Hash;
  return new URL("https://mml.infura-ipfs.io/ipfs/" + hash);
}
