import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

type DropdownSelectorProps = {
  label: string;
  values: string[];
  valueToDisplayName: (value: string) => any;
  value: string;
  setValue: (value: string) => void;
};

export default function Dropdown(props: DropdownSelectorProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.value}
        label={props.label}
        size={"small"}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      >
        {props.values.map((v) => (
          <MenuItem key={v} value={v}>
            {props.valueToDisplayName(v)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
