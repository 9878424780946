import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
        },
        ul: {
          listStyle: "none",
        },
      },
    },
  },
  palette: {
    mode: "dark",
  },
  typography: {
    fontSize: 14,
  },
  spacing: 10,
});
