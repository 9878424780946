import { Box, Stack, TextField } from "@mui/material";
import React from "react";

import { uploadFile } from "../../contentAddressedStorage";
import FileImporter from "./FileImporter";

export type MeshSelectorProps = {
  mesh: string;
  setMesh: (mesh: string) => void;
};

export default function MeshSelector(props: MeshSelectorProps) {
  return (
    <Stack direction="row" spacing={1}>
      <TextField
        variant={"outlined"}
        label={"Mesh URL"}
        value={props.mesh.toString()}
        size={"small"}
        sx={{ width: "75%" }}
        onChange={(e) => props.setMesh(e.target.value)}
      ></TextField>
      <FileImporter
        label={"Upload"}
        sx={{ width: "25%" }}
        onFile={async (file: File) => {
          const url = await uploadFile(file);
          props.setMesh(url.toString());
        }}
      />
    </Stack>
  );
}
