import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";

import SimpleMain from "my-lib/src/components/SimpleMain";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<SimpleMain />);
